const propertyData = {
    "url": "http://16320SWSylvanCt.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "16320 SW Sylvan Ct", "city": "Tigard", "state":"Oregon", "zipcode": "97224"},
    "display_address": "16320 SW Sylvan Ct",
    "property": {
      "listing_price": "$474,990",
      "bedrooms": "4",
      "baths": "2.5",
      "sq_feet": "1952",
      "lot_size": "7405",
      "rmls": "20238097",
      "built_in": "1980",
      "blurb": "Meticulously maintained home in desirable Pick's Landing! Private setting in quiet cul-de-sac. Large kitchen with beautiful cabinets, granite counters, stainless appliances, wood floors & skylight. Living room w/wood burning fireplace. Master bedroom w/walk-in closet & private bath. Large family & utility room on lower level. Spacious backyard w/large deck & fire pit, perfect for entertaining. Abundant storage in oversized garage. Easy access to I-5, 217, Cooks Park, wine country & shopping.",
      "ammenities": [
        
      ]
    },
    "image_count": 27,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.5986282838194!2d-123.13407618443999!3d45.53828197910194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54951959d4ac4be9%3A0x90cc7dedafcf2f8!2s3460%20Oakcrest%20Dr%2C%20Forest%20Grove%2C%20OR%2097116!5e0!3m2!1sen!2sus!4v1578068622715!5m2!1sen!2sus",
        "youtube":"YOsOXX_2Q4U"
    }
  }

export default propertyData;

